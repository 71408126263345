export default {
    path: "middle-man",
    name: "card-plugin-middle-man",
    redirect: "/card/plugin/middle-man/rule",
    component:()=>import("@/view/card/plugin/middle-man"),
    children: [
        {
            path: "rule",
            name: "card-plugin-middle-man-rule",
            component: () => import("@/view/card/plugin/middle-man/rule"),
            meta: {
                title: "新会员分销规则"
            },
        },
    ],
}