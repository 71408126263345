import Vue from "vue";
import config from "@/config";

const article = {};
const base = config.api_base + "/card/article";

article.cateAll = (params) => Vue.axios.post(base + "/cate-all", params);
article.cateEdit = (params) => Vue.axios.post(base + "/cate-edit", params);
article.cateDel = (params) => Vue.axios.post(base + "/cate-del", params);
article.detail = (params) => Vue.axios.post(base + "/detail", params);
article.page = (params) => Vue.axios.post(base + "/page", params);
article.edit = (params) => Vue.axios.post(base + "/edit", params);
article.del = (params) => Vue.axios.post(base + "/del", params);

export default article;