import DefaultMain from "@/components/layouts/DefaultMain";

export default [
    {
        path: "/system",
        component: DefaultMain,
        redirect: "/system/entry",
        name: "system",
        meta: {
            title: "系统配置"
        },
        children: [
            {
                path: "entry",
                name: "systemEntry",
                component: () => import("@/view/system/entry"),
                meta: {
                    title: "入口链接"
                }
            },

            {
                path: "wx-official",
                name: "systemWxOfficial",
                component: () => import("@/view/system/wxOfficial"),
                meta: {
                    title: "微信公众号"
                }
            },
            {
                path: "wx-official-substitute",
                name: "systemWxOfficialSubstitute",
                component: () => import("@/view/system/wxOfficialSubstitute"),
                meta: {
                    title: "借权发放佣金配置"
                }
            },
            {
                path: "wxapp",
                name: "systemWxapp",
                component: () => import("@/view/system/wxapp"),
                meta: {
                    title: "微信小程序"
                }
            },
            {
                path: "wx-pay",
                name: "shopSystemWxPay",
                component: () => import("@/view/system/wxPay"),
                meta: {
                    title: "微信支付"
                }
            },
            {
                path: "ali-pay",
                name: "shopSystemAliPay",
                component: () => import("@/view/system/aliPay"),
                meta: {
                    title: "阿里支付"
                }
            },
            {
                path: "sms",
                name: "systemSms",
                component: () => import("@/view/system/sms"),
                meta: {
                    title: "短信配置"
                }
            },
            {
                path: "attach",
                name: "system-attach",
                component: () => import("@/view/system/attach"),
                meta: {
                    title: "附件配置"
                }
            },
            {
                path: "setting",
                name: "system-setting",
                component: () => import("@/view/system/setting"),
                meta: {
                    title: "基础配置"
                }
            },
            {
                path: "ad-popup",
                name: "system-ad-popup",
                component: () => import("@/view/system/adPopup"),
                meta: {
                    title: "弹窗广告"
                }
            },
        ]
    }
]