import finance from "@/router/user/finance";
import visitor from "@/router/user/visitor";

export default [
    {
        path: "/user",
        component: () => import("@/components/layouts/DefaultMain"),
        redirect: "/user/manage",
        name: "user",
        meta: {
            title: "用户管理"
        },
        children: [
            {
                path: "manage",
                name: "userManage",
                component: () => import("@/view/user/manage"),
                meta: {
                    title: "全部用户"
                }
            },
            visitor,
            finance,
        ]
    }
]