import Vue from "vue";
import config from "@/config";

const order = {};
const base = config.api_base + "/shop/order"



order.search = (params)=>Vue.axios.post( base + "/search",params);
order.import2sendAction = base + "/import2send?i="

order.handFinished = (params)=>Vue.axios.post( base + "/hand-finished",params);
order.send = (params)=>Vue.axios.post( base + "/send",params);
order.handPay = (params)=>Vue.axios.post( base + "/hand-pay",params);

export default order;