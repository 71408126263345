export default {
    path: "home-page",
    name: "card-plugin-home-page",
    redirect: "/card/plugin/home-page/publish-log",
    component:()=>import("@/view/card/plugin/home-page"),
    children: [
        {
            path: "publish-log",
            name: "card-plugin-home-page-publish-log",
            component: () => import("@/view/card/plugin/home-page/publish-log"),
            meta: {
                title: "发布记录"
            }
        },
        {
            path: "fast-page",
            name: "card-plugin-home-page-fast-page",
            component: () => import("@/view/card/plugin/home-page/fast-page"),
            meta: {
                title: "极速首页"
            },
            children:[
                {
                    path: "conf",
                    name:"card-plugin-home-page-fast-page-conf",
                    component: ()=>import("@/view/card/plugin/home-page/fast-page/conf"),
                    meta:{
                        title:"基础配置",
                    }
                },
                {
                    path: "banner",
                    name:"card-plugin-home-page-fast-page-banner",
                    component: ()=>import("@/view/card/plugin/home-page/fast-page/banner"),
                    meta:{
                        title:"轮播图",
                    }
                },
                {
                    path: "gird",
                    name:"card-plugin-home-page-fast-page-gird",
                    component: ()=>import("@/view/card/plugin/home-page/fast-page/gird"),
                    meta:{
                        title:"宫格导航",
                    }
                },
                {
                    path: "tabbar",
                    name:"card-plugin-home-page-fast-page-tabbar",
                    component: ()=>import("@/view/card/plugin/home-page/fast-page/tabbar"),
                    meta:{
                        title:"自定义菜单",
                    }
                },
                {
                    path: "float-bar",
                    name:"card-plugin-home-page-fast-page-float-bar",
                    component: ()=>import("@/view/card/plugin/home-page/fast-page/float-bar"),
                    meta:{
                        title:"浮动菜单",
                    }
                },
            ]
        },
        {
            path: "video-page",
            name: "card-plugin-home-page-video-page",
            component: () => import("@/view/card/plugin/home-page/video-page"),
            meta: {
                title: "视频首页"
            },
            children:[
                {
                    path: "conf",
                    name:"card-plugin-home-page-video-page-conf",
                    component: ()=>import("@/view/card/plugin/home-page/video-page/conf"),
                    meta:{
                        title:"基础配置",
                    }
                },
                {
                    path: "comment",
                    name:"card-plugin-home-page-fast-video-comment",
                    component: ()=>import("@/view/card/plugin/home-page/video-page/comment"),
                    meta:{
                        title:"虚拟评论",
                    }
                },
                {
                    path: "video-count-down-award-log",
                    name:"card-plugin-home-page-video-page-video-count-down-award-log",
                    component: ()=>import("@/view/card/plugin/home-page/video-page/video-count-down-award-log"),
                    meta:{
                        title:"红包发放记录",
                    }
                },
                {
                    path: "video",
                    name:"card-plugin-home-page-video-page-video",
                    component: ()=>import("@/view/card/plugin/home-page/video-page/video"),
                    meta:{
                        title:"视频管理",
                    }
                },
                {
                    path: "tabbar",
                    name:"card-plugin-home-page-video-page-tabbar",
                    component: ()=>import("@/view/card/plugin/home-page/video-page/tabbar"),
                    meta:{
                        title:"自定义菜单",
                    }
                },
            ]
        },
    ],
}