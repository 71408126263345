import config from "@/config";
import Vue from "vue";

const base = config.api_base + "/card/plugin/cdkey";


export default {
    create: (params) => Vue.axios.post(base + "/create", params),
    search: (params) => Vue.axios.post(base + "/search", params),
    export: (params) => Vue.axios.post(base + "/export", params),
    del: (params) => Vue.axios.post(base + "/del", params),
    clear: (params) => Vue.axios.post(base + "/clear", params),
    userSearch: (params) => Vue.axios.post(base + "/user-search", params),
    userAdd: (params) => Vue.axios.post(base + "/user-add", params),
    memberSearch: (params) => Vue.axios.post(base + "/member-search", params),
    uidBindCodeIds: (params) => Vue.axios.post(base + "/uid-bind-code-ids", params),
}