export default {
    path: "principal",
    name: "card-plugin-principal",
    redirect: "/card/plugin/principal/rule",
    component:()=>import("@/view/card/plugin/principal"),
    children: [
        {
            path: "rule",
            name: "card-plugin-principal-rule",
            component: () => import("@/view/card/plugin/principal/rule"),
            meta: {
                title: "主理人规则配置"
            },

        },
        {
            path: "user",
            name: "card-plugin-principal-user",
            component: () => import("@/view/card/plugin/principal/user"),
            meta: {
                title: "主理人管理"
            }
        },
        {
            path: "order",
            name: "card-plugin-principal-order",
            component: () => import("@/view/card/plugin/principal/order"),
            meta: {
                title: "主理人订单"
            }
        },
    ],
}