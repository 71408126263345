import config from "@/config";
import Vue from "vue";

const base = config.api_base + "/card/plugin/home-page";

const fastPageBase  = base + "/fast-page"

const videoPageBase  = base + "/video-page"

export default {
    publishLog: (params) => Vue.axios.post(base + "/publish-log", params),
    publishLogEdit: (params) => Vue.axios.post(base + "/publish-log-edit", params),

    fastPage:{
        conf: (params) => Vue.axios.post(fastPageBase + "/conf", params),
        confEdit: (params) => Vue.axios.post(fastPageBase + "/conf-edit", params),
        bannerAll: (params) => Vue.axios.post(fastPageBase + "/banner-all", params),
        tabbarAll: (params) => Vue.axios.post(fastPageBase + "/tabbar-all", params),
        gridAll: (params) => Vue.axios.post(fastPageBase + "/grid-all", params),
        floatBarAll: (params) => Vue.axios.post(fastPageBase + "/float-bar-all", params),
        targetNavDel: (params) => Vue.axios.post(fastPageBase + "/target-nav-del", params),
        targetNavEdit: (params) => Vue.axios.post(fastPageBase + "/target-nav-edit", params),
    },
    videoPage:{
        conf: (params) => Vue.axios.post(videoPageBase + "/conf", params),
        confEdit: (params) => Vue.axios.post(videoPageBase + "/conf-edit", params),
        commentSearch: (params) => Vue.axios.post(videoPageBase + "/comment-search", params),
        commentEdit: (params) => Vue.axios.post(videoPageBase + "/comment-edit", params),
        commentDel: (params) => Vue.axios.post(videoPageBase + "/comment-del", params),
        tabbarAll: (params) => Vue.axios.post(videoPageBase + "/tabbar-all", params),
        tabbarEdit: (params) => Vue.axios.post(videoPageBase + "/tabbar-edit", params),
        tabbarDel: (params) => Vue.axios.post(videoPageBase + "/tabbar-del", params),
        videoSearch: (params) => Vue.axios.post(videoPageBase + "/video-search", params),
        videoEdit: (params) => Vue.axios.post(videoPageBase + "/video-edit", params),
        videoDel: (params) => Vue.axios.post(videoPageBase + "/video-del", params),
        videoCountDownAwardLog: (params) => Vue.axios.post(videoPageBase + "/video-count-down-award-log", params),

    }
}