export default {
    path: "visitor",
    component: () => import("@/view/user/visitor"),
    redirect: "/user/visitor/mini",
    name: "userVisitor",
    meta: {
        title: "用户管理"
    },
    children: [
        {
            path: "mini",
            name: "userVisitorMini",
            component: () => import("@/view/user/visitor/mini"),
            meta: {
                title: "小程序访客"
            }
        },
        {
            path: "official",
            name: "userVisitorOfficial",
            component: () => import("@/view/user/visitor/official"),
            meta: {
                title: "公众号访客"
            }
        },
        {
            path: "official-sub",
            name: "userVisitorOfficialSub",
            component: () => import("@/view/user/visitor/officialSub"),
            meta: {
                title: "公众号借权访客"
            }
        },
    ]
}
