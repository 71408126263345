export default {
    path: "finance",
    component: () => import("@/view/user/finance"),
    redirect: "/user/finance/wait",
    name: "userFinance",
    meta: {
        title: "财务"
    },
    children: [
        {
            path: "wait",
            name: "userFinanceWait",
            component: () => import("@/view/user/finance/wait"),
            meta: {
                title: "待打款"
            }
        },
        {
            path: "log",
            name: "userFinanceLog",
            component: () => import("@/view/user/finance/log"),
            meta: {
                title: "打款记录"
            }
        }
    ]
}
