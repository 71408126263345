import Vue from "vue";
import config from "@/config";
import plugin from "@/api/card/plugin";
import article from "@/api/card/article";

const card = {};
const base = config.api_base + "/card";

card.dashboard = (params) => Vue.axios.post(base + "/dashboard", params);
card.dashboardV2 = (params) => Vue.axios.post(base + "/dashboard-v2", params);

card.userLargeQrcode = (params) => Vue.axios.post(base + "/user-large-qrcode", params);

card.conf = (params) => Vue.axios.post(base + "/conf", params);
card.confEdit = (params) => Vue.axios.post(base + "/conf-edit", params);
card.confBannerAll = (params) => Vue.axios.post(base + "/conf-banner-all", params);
card.confBannerDel = (params) => Vue.axios.post(base + "/conf-banner-del", params);
card.confBannerEdit = (params) => Vue.axios.post(base + "/conf-banner-edit", params);
card.confRightsAll = (params) => Vue.axios.post(base + "/conf-rights-all", params);
card.confRightsDel = (params) => Vue.axios.post(base + "/conf-rights-del", params);
card.confRightsEdit = (params) => Vue.axios.post(base + "/conf-rights-edit", params);
card.confNoticeSearch = (params) => Vue.axios.post(base + "/conf-notice-search", params);
card.confNoticeOne = (params) => Vue.axios.post(base + "/conf-notice-one", params);
card.confNoticeEdit = (params) => Vue.axios.post(base + "/conf-notice-edit", params);
card.confNoticeDel = (params) => Vue.axios.post(base + "/conf-notice-del", params);

card.merchantCateAll = (params) => Vue.axios.post(base + "/merchant-cate-all", params);
card.merchantCateEdit = (params) => Vue.axios.post(base + "/merchant-cate-edit", params);
card.merchantCateDel = (params) => Vue.axios.post(base + "/merchant-cate-del", params);

card.merchantStatus = (params) => Vue.axios.post(base + "/merchant-status", params);
card.merchantSearch = (params) => Vue.axios.post(base + "/merchant-search", params);
card.merchantOne = (params) => Vue.axios.post(base + "/merchant-one", params);
card.merchantEdit = (params) => Vue.axios.post(base + "/merchant-edit", params);
card.merchantDel = (params) => Vue.axios.post(base + "/merchant-del", params);

card.merchantClerkSearch = (params) => Vue.axios.post(base + "/merchant-clerk-search", params);
card.merchantClerkEdit = (params) => Vue.axios.post(base + "/merchant-clerk-edit", params);
card.merchantClerkDel = (params) => Vue.axios.post(base + "/merchant-clerk-del", params);

card.merchantCouponSearch = (params) => Vue.axios.post(base + "/merchant-coupon-search", params);
card.merchantCouponOne = (params) => Vue.axios.post(base + "/merchant-coupon-one", params);
card.merchantCouponEdit = (params) => Vue.axios.post(base + "/merchant-coupon-edit", params);
card.merchantCouponDel = (params) => Vue.axios.post(base + "/merchant-coupon-del", params);

card.ruleMerchant = (params) => Vue.axios.post(base + "/rule-merchant", params);
card.ruleMerchantEdit = (params) => Vue.axios.post(base + "/rule-merchant-edit", params);

card.ruleCard = (params) => Vue.axios.post(base + "/rule-card", params);
card.ruleCardEdit = (params) => Vue.axios.post(base + "/rule-card-edit", params);



card.cashierLog = (params) => Vue.axios.post(base + "/cashier-log", params);
card.cashierLogExport = (params) => Vue.axios.post(base + "/cashier-log-export", params);

card.memberSearch = (params) => Vue.axios.post(base + "/member-search", params);

card.orderSearch = (params) => Vue.axios.post(base + "/order-search", params);
card.orderAwardSearch = (params) => Vue.axios.post(base + "/order-award-search", params);


card.posterList = (params) => Vue.axios.post(base + "/poster-list", params);
card.posterEdit = (params) => Vue.axios.post(base + "/poster-edit", params);
card.posterOne = (params) => Vue.axios.post(base + "/poster-one", params);
card.posterDel = (params) => Vue.axios.post(base + "/poster-del", params);
card.posterClear = (params) => Vue.axios.post(base + "/poster-clear", params);


card.manageAll = (params) => Vue.axios.post(base + "/manage-all", params);
card.manageAdd = (params) => Vue.axios.post(base + "/manage-add", params);
card.manageDel = (params) => Vue.axios.post(base + "/manage-del", params);

card.pageMineBanner = (params) => Vue.axios.post(base + "/page-mine-banner", params);
card.pageMineBannerEdit = (params) => Vue.axios.post(base + "/page-mine-banner-edit", params);
card.pageMineBannerDel = (params) => Vue.axios.post(base + "/page-mine-banner-del", params);




card.couponPosterConf = (params) => Vue.axios.post(base + "/coupon-poster-conf", params);
card.couponPosterConfEdit = (params) => Vue.axios.post(base + "/coupon-poster-conf-edit", params);
card.couponPosterClear = (params) => Vue.axios.post(base + "/coupon-poster-clear", params);

export default {
    ...card,
    plugin,
    article,
};