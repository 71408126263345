import axios from "@/api/axios";
import config from "@/config";
const founder = {};
const base = config.api_base + "/founder";
founder.uniDel = (params)=>axios.post(base + "/uni-del",params);
founder.uniEdit = (params)=>axios.post(base + "/uni-edit",params);
founder.uniSearch = (params)=>axios.post(base + "/uni-search",params);
founder.uniTotal = (params)=>axios.post(base + "/uni-total",params);

founder.accountSearch = (params)=>axios.post( base + "/account-search",params);
founder.accountResetPassword = (params)=>axios.post( base + "/account-reset-password",params);
founder.accountDel = (params)=>axios.post( base + "/account-del",params);
founder.accountAdd = (params)=>axios.post( base + "/account-add",params);

founder.systemFetch = (params)=>axios.post( base + "/system-fetch",params);
founder.systemEdit = (params)=>axios.post( base + "/system-edit",params);

founder.versionRemote = (params)=>axios.post( base + "/version-remote",params);
founder.versionUpdate = (params)=>axios.post( base + "/version-update",params);

founder.upgradeLog = (params)=>axios.post( base + "/upgrade-log",params);

export default founder;