import config from "@/config";
import Vue from "vue";
import homePage from "@/api/card/plugin/homePage";
import wifi from "@/api/card/plugin/wifi";
import cdkey from "@/api/card/plugin/cdkey";
import middleMan from "@/api/card/plugin/middle-man";
import principal from "@/api/card/plugin/principal";

const base = config.api_base + "/card/plugin";

const manage = {
    enable:(params)=>Vue.axios.post( base + "/manage/enable",params),
    added:(params)=>Vue.axios.post( base + "/manage/added",params),
    addedChange: (params)=>Vue.axios.post( base + "/manage/added-change",params),
}

const agent = {
    rule: (params) => Vue.axios.post(base + "/agent/rule", params),
    ruleEdit : (params) => Vue.axios.post(base + "/agent/rule-edit", params),
    search : (params) => Vue.axios.post(base + "/agent/search", params),
    handsApply :(params) => Vue.axios.post(base + "/agent/hands-apply", params),
    del : (params) => Vue.axios.post(base + "/agent/del", params),
}
const wholesaler = {
    conf : (params) => Vue.axios.post(base + "/wholesaler/conf", params),
    confEdit : (params) => Vue.axios.post(base + "/wholesaler/conf-edit", params),
    modeRule : (params) => Vue.axios.post(base + "/wholesaler/mode-rule", params),
    modeRuleEdit : (params) => Vue.axios.post(base + "/wholesaler/mode-rule-edit", params),
    activationLog : (params) => Vue.axios.post(base + "/wholesaler/activation-log", params),
    userSearch : (params) => Vue.axios.post(base + "/wholesaler/user-search", params),
    userSearchExport : (params) => Vue.axios.post(base + "/wholesaler/user-search-export", params),
    userDetail : (params) => Vue.axios.post(base + "/wholesaler/user-detail", params),
    userTeam : (params) => Vue.axios.post(base + "/wholesaler/user-team", params),
    userInviteLinkSearch : (params) => Vue.axios.post(base + "/wholesaler/user-invite-link-search", params),
    orderSearch : (params) => Vue.axios.post(base + "/wholesaler/order-search", params),
    recharge : (params) => Vue.axios.post(base + "/wholesaler/recharge", params),

}

export default {
    manage,
    agent,
    wholesaler,
    homePage,
    wifi,
    cdkey,
    middleMan,
    principal,
};