import account from "@/api/account";
import attach from "@/api/attach";
import founder from "@/api/founder";
import shop from "@/api/shop";
import user from "@/api/user";
import common from "@/api/common";
import system from "@/api/system";
import card from "@/api/card";

export default {
    account,
    attach,
    shop,
    user,

    system,
    card,
    //以下为完成迁移的接口
    founder,
    common,
}

