import Vue from "vue";

import config from "@/config";
import { vuex } from "@/common/util";

const common = {};
const base = config.api_base + "/common"

common.globalSet = (params)=>Vue.axios.get( base + "/global-set",params).then(res=>{
    vuex("vuex_global_set",res);
});

export default common