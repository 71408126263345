import DefaultMain from "@/components/layouts/DefaultMain";
import agent from "@/router/card/plugin/agent";
import wholesaler from "@/router/card/plugin/wholesaler";
import homePage from "@/router/card/plugin/home-page";
import wifi from "@/router/card/plugin/wifi";
import cdkey from "@/router/card/plugin/cdkey";
import middleMan from "@/router/card/plugin/middle-man";
import principal from "@/router/card/plugin/principal";

export default {
    path: "plugin",
    name: "card-plugin",
    redirect: "/card/plugin/manage",
    component:DefaultMain,
    children: [
        {
            path: "manage",
            name: "card-plugin-manage",
            component: () => import("@/view/card/plugin/manage"),
            meta: {
                title: "插件中心"
            }
        },
        agent,
        wholesaler,
        homePage,
        wifi,
        cdkey,
        middleMan,
        principal,
    ],
}