import config from "@/config";
import Vue from "vue";

const base = config.api_base + "/card/plugin/principal";


export default {
    rule: (params) => Vue.axios.post(base + "/rule", params),
    ruleEdit: (params) => Vue.axios.post(base + "/rule-edit", params),
    userSearch: (params) => Vue.axios.post(base + "/user-search", params),
    userDetail: (params) => Vue.axios.post(base + "/user-detail", params),
    userTeam : (params) => Vue.axios.post(base + "/user-team", params),
    cardRecharge: (params) => Vue.axios.post(base + "/card-recharge", params),
    cardLog: (params) => Vue.axios.post(base + "/card-log", params),
    orderSearch: (params) => Vue.axios.post(base + "/order-search", params),
    pQrcode: (params) => Vue.axios.post(base + "/p-qrcode", params),
}