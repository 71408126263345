import DefaultMain from "@/components/layouts/DefaultMain";
import plugin from "@/router/card/plugin";
import article from "@/router/card/article";

export default [
    {
        path: "/card",
        component: DefaultMain,
        redirect: "/card/dashboard",
        name: "card",
        meta: {
            title: "免单卡"
        },
        children: [
            {
                path: "dashboard",
                name: "card-dashboard",
                component: () => import("@/view/card/dashboard"),
                meta: {
                    title: "数据概览"
                }
            },
            {
                path: "merchant",
                name: "card-merchant",
                component: () => import("@/view/card/merchant"),
                redirect: "/card/merchant/manage",
                meta: {
                    title: "商家管理"
                },
                children: [
                    {
                        path: "cate",
                        name: "card-merchant-cate",
                        component: () => import("@/view/card/merchant/cate"),
                        meta: {
                            title: "商家分类"
                        }
                    },
                    {
                        path: "stop",
                        name: "card-merchant-stop",
                        component: () => import("@/view/card/merchant/stop"),
                        meta: {
                            title: "已停用"
                        }
                    },
                    {
                        path: "manage",
                        name: "card-merchant-manage",
                        component: () => import("@/view/card/merchant/manage"),
                        meta: {
                            title: "入驻中"
                        }
                    },
                    {
                        path: "edit",
                        name: "card-merchant-edit",
                        component: () => import("@/view/card/merchant/edit"),
                        meta: {
                            title: "商家编辑"
                        }
                    },
                    {
                        path: "clerk",
                        name: "card-merchant-clerk",
                        component: () => import("@/view/card/merchant/clerk"),
                        meta: {
                            title: "员工管理"
                        }
                    },
                    {
                        path: "coupon",
                        name: "card-merchant-coupon",
                        component: () => import("@/view/card/merchant/coupon"),
                        meta: {
                            title: "卡券管理"
                        }
                    },
                    {
                        path: "coupon-edit",
                        name: "card-merchant-coupon-edit",
                        component: () => import("@/view/card/merchant/coupon-edit"),
                        meta: {
                            title: "卡券编辑"
                        }
                    },
                ]
            },
            {
                path: "user",
                name: "card-user",
                component: DefaultMain,
                redirect: "/card/user/manage",
                meta: {
                    title: "免单会员"
                },
                children: [
                    {
                        path: "manage",
                        name: "card-user-manage",
                        component: () => import("@/view/card/user/manage"),
                        meta: {
                            title: "免单会员列表"
                        }
                    },
                ]
            },
            {
                path: "cashier",
                name: "card-cashier",
                component: DefaultMain,
                redirect: "/card/cashier/manage",
                meta: {
                    title: "核销数据"
                },
                children: [
                    {
                        path: "manage",
                        name: "cashier-manage",
                        component: () => import("@/view/card/cashier/manage"),
                        meta: {
                            title: "核销记录"
                        }
                    },
                ]
            },
            {
                path: "order",
                name: "card-order",
                component: () => import("@/view/card/order"),
                meta: {
                    title: "订单管理"
                }
            },
            {
                path: "system",
                name: "card-system",
                component: () => import("@/view/card/system"),
                redirect: "/card/system/card-conf-edit",
                meta: {title: "基础配置"},
                children: [
                    {
                        path: "card-conf-edit",
                        name: "card-conf-edit",
                        component: () => import("@/view/card/system/conf/edit"),
                        meta: {
                            title: "免单卡购买页面"
                        },
                    },
                    {
                        path: "card-conf-banner",
                        name: "card-conf-banner",
                        component: () => import("@/view/card/system/conf/banner"),
                        meta: {
                            title: "免单卡购买页轮播"
                        },
                    },
                    {
                        path: "card-conf-rights",
                        name: "card-conf-rights",
                        component: () => import("@/view/card/system/conf/rights"),
                        meta: {
                            title: "免单卡权益"
                        },
                    },
                    {
                        path: "card-conf-notice",
                        name: "card-conf-notice",
                        component: () => import("@/view/card/system/conf/notice"),
                        meta: {
                            title: "免单卡公告"
                        },
                    },
                    {
                        path: "card-conf-notice-edit",
                        name: "card-conf-notice-edit",
                        component: () => import("@/view/card/system/conf/notice-edit"),
                        meta: {
                            title: "免单卡公告编辑"
                        },
                    },
                    {
                        path: "card-conf-page-mine-banner",
                        name: "card-conf-page-mine-banner",
                        component: () => import("@/view/card/system/conf/page-mine-banner"),
                        meta: {
                            title: "个人中心轮播图"
                        },
                    },
                    {
                        path: "card-rule-card",
                        name: "card-rule-card",
                        component: () => import("@/view/card/system/rule/card"),
                        meta: {
                            title: "免单卡规则"
                        },
                    },
                    {
                        path: "card-rule-merchant",
                        name: "card-rule-merchant",
                        component: () => import("@/view/card/system/rule/merchant"),
                        meta: {
                            title: "商家规则"
                        },
                    },
                    {
                        path: "card-rule-coupon-poster",
                        name: "card-rule-coupon-poster",
                        component: () => import("@/view/card/system/rule/couponPoster"),
                        meta: {
                            title: "卡券海报管理"
                        },
                    },
                    {
                        path: "card-rule-poster",
                        name: "card-rule-poster",
                        component: () => import("@/view/card/system/rule/poster"),
                        meta: {
                            title: "免单海报管理"
                        },
                    },
                    {
                        path: "card-rule-poster-edit",
                        name: "card-rule-poster-edit",
                        component: () => import("@/view/card/system/rule/poster-edit"),
                        meta: {
                            title: "免单海报编辑"
                        },
                    },
                    {
                        path: "card-rule-manage",
                        name: "card-rule-manage",
                        component: () => import("@/view/card/system/rule/manage"),
                        meta: {
                            title: "平台管理员"
                        },
                    },
                ]
            },
            article,
            plugin,
            {
                path: "rule",
                name: "card-rule",
                component: () => import("@/view/card/system/rule"),
                meta: {
                    title: "基础配置"
                },
                children: []
            },
            {
                path: "agent",
                name: "card-agent",
                component: () => import("@/view/card/plugin/agent"),
                redirect: "/card/agent/manage",
                meta: {
                    title: "代理商"
                },
                children: [
                    {
                        path: "manage",
                        name: "card-agent-manage",
                        component: () => import("@/view/card/plugin/agent/manage"),
                        meta: {
                            title: "代理列表"
                        }
                    },
                ]
            },
        ]
    }
]